import PropTypes from 'prop-types';
import Styled from '@emotion/styled';
import Submenu from './Submenu';
import HeadlineSection from '../HeadlineSection';
import SecondaryTitle from '../../styles/SecondaryTitle';
import Subtitle from '../../styles/Subtitle';
import ImageSlider from '../ImageSlider/ImageSlider';
import DataCenterFeatureDetail from './DataCenterFeatureDetail';
import dataCentersConfig from './config';

const PageWrapper = Styled.div`
`;

const SplitPageWrapper = Styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
  padding: 0 6%;
  padding-bottom: 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 5rem;
    padding: 0 12%;
    padding-bottom: 6rem;
  }

  @media (min-width: 1440px) {
    padding: 0 24%;
    padding-bottom: 6rem;
  }
`;
const Left = Styled.div`
  flex: 1.5;
`;
const Right = Styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  padding-left: 0;

  @media (min-width: 768px) {
    gap: 2rem;
    padding-left: 2rem;
  }
`;
const RightTitle = Styled(SecondaryTitle)`
  color: ${({ theme }) => theme.colors.charcoal};
`;
const FeatureTitle = Styled(Subtitle)`
  color: ${({ theme }) => theme.colors.darkBlue};
`;

const DataCenterFeature = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.4rem;
`;

const DataCenterTemplate = ({
  pageTitle,
  pageSubtitle,
  subtitleWidth,
  sliderImages,
  specs,
}) => {
  const { menuLabels } = dataCentersConfig.global.submenuSection;
  return (
    <PageWrapper>
      <HeadlineSection
        titleType="secondary"
        titleWidth={85}
        subtitleWidth={subtitleWidth}
        paddingPreset="topOfPage"
        titleColor="darkBlue"
        subtitleColor="text"
        sectionTitle={pageTitle}
        sectionSubtitle={pageSubtitle}
      />
      <Submenu menuLabels={menuLabels} />
      <SplitPageWrapper>
        <Left>
          <ImageSlider images={sliderImages} showDots={true} />
        </Left>
        <Right>
          <RightTitle>Specifications</RightTitle>
          {specs.map((spec, index) => (
            <DataCenterFeature key={index}>
              <FeatureTitle>{spec.featureTitle}:</FeatureTitle>
              <DataCenterFeatureDetail
                featureDescriptions={spec.featureDescriptions}
                featureIconName={spec.featureIconName}
              />
            </DataCenterFeature>
          ))}
        </Right>
      </SplitPageWrapper>
    </PageWrapper>
  );
};

DataCenterTemplate.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pageSubtitle: PropTypes.string,
  subtitleWidth: PropTypes.number.isRequired,
  sliderImages: PropTypes.array,
  specs: PropTypes.array,
  menuLabels: PropTypes.array,
};

export default DataCenterTemplate;
