import PropTypes from 'prop-types';
import Styled from '@emotion/styled';
import React, { useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Subtitle from '../../styles/Subtitle';
import { GlobalContext } from '../../context/GlobalContext';

const MenuArea = Styled.div`
  box-sizing: border-box;
  overflow-x: scroll;
  height: 4rem;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 6%;

  a {
    flex: 1;  
  }

  @media (min-width: 768px) {
    overflow-x: auto;
    width: 100vw;
    padding: 0 12%;
  }

  @media (min-width: 1440px) {
    padding: 0 24%;
  }
`;

const MenuLabel = Styled(Subtitle)`
  border-radius: 2rem;
  border: 1px solid ${({ theme }) => theme.colors.darkBlue};
  background-color: ${({ isActive, theme }) => (isActive ? theme.colors.greenAccent : theme.colors.white)};
  color: ${({ isActive, theme }) => (isActive ? theme.colors.white : theme.colors.darkBlue)};
  padding: 0.4rem 4rem;
  text-align: center;
  transition-property: background-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

    @media (min-width: 768px) {
      padding: 0.4rem 0;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.greenAccent};
        color: ${({ theme }) => theme.colors.white};
      }
    }
`;

const Submenu = ({ menuLabels }) => {
  const { isActive } = useContext(GlobalContext);
  const menuAreaRef = useRef(null);

  // Save the scroll position in sessionStorage
  const saveScrollPosition = () => {
    if (menuAreaRef.current) {
      sessionStorage.setItem(
        'submenuScrollPosition',
        menuAreaRef.current.scrollLeft
      );
    }
  };

  // Restore the scroll position from sessionStorage
  const restoreScrollPosition = () => {
    const savedPosition = sessionStorage.getItem('submenuScrollPosition');
    if (menuAreaRef.current && savedPosition !== null) {
      menuAreaRef.current.scrollLeft = parseInt(savedPosition, 10);
    }
  };

  useEffect(() => {
    restoreScrollPosition();

    // Save scroll position when the component unmounts
    return () => {
      saveScrollPosition();
    };
  }, []);

  return (
    <MenuArea ref={menuAreaRef} onScroll={saveScrollPosition}>
      {menuLabels.map((menuLabel, index) => (
        <Link to={menuLabel.link} key={index}>
          <MenuLabel isActive={isActive(menuLabel.link)}>
            {menuLabel.label}
          </MenuLabel>
        </Link>
      ))}
    </MenuArea>
  );
};

Submenu.propTypes = {
  menuLabels: PropTypes.array,
};

export default Submenu;
