import Styled from '@emotion/styled';
import Header from '../components/Header';
import SiteMap from '../components/SiteMap';
import Footer from '../components/Footer';

import PageHeader from '../components/PageHeader';
import ApproachStageCard from '../components/ApproachPage/ApproachStageCard';
import approachConfig from '../components/ApproachPage/config';

const ApproachWrapper = Styled.div``;

const Approach = () => {
  const { sectionTitle, sectionSubtitle } = approachConfig.headlineSection;
  const { stageCards } = approachConfig.stageCard;
  return (
    <ApproachWrapper>
      <Header />
      <PageHeader title={sectionTitle} subtitle={sectionSubtitle} />
      {stageCards.map((stageCard, index) => (
        <ApproachStageCard
          key={index}
          cardBgColor={stageCard.cardBgColor}
          primaryTextColor={stageCard.primaryTextColor}
          secondaryTextColor={stageCard.secondaryTextColor}
          iconSrc={stageCard.iconSrc}
          iconLabel={stageCard.iconLabel}
          iconBgColor={stageCard.iconBgColor}
          iconShrink={stageCard.iconShrink}
          stageNumber={stageCard.stageNumber}
          stageTitle={stageCard.stageTitle}
          featureTexts={stageCard.featureTexts}
        />
      ))}
      <SiteMap />
      <Footer />
    </ApproachWrapper>
  );
};
export default Approach;
