import Styled from '@emotion/styled';

const PrimaryTitle = Styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fontWeights.extrabold};
  font-style: normal;
  font-size: 11vw;
  letter-spacing: -0.2vw;
  line-height: 10vw;
  cursor: default;
  
  a {
    transition: color 0.2s ease-in-out;
  }
  a: hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.lightBlue};
  }

  @media (min-width: 768px) {
    font-size: 8vw;
    line-height: 7vw;
    white-space: pre-line;
  }

  @media (min-width: 1440px) {
    font-size: 6vw;
    line-height: 5vw;
  }
`;

export default PrimaryTitle;
