import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import SecondaryTitle from '../../styles/SecondaryTitle';
import Image from '../Image';
import BulletPoint from '../BulletPoint';

const PagePadding = styled.div`
  box-sizing: border-box;
  padding: 0 6%;

  @media (min-width: 768px) {
    padding: 0 12%;
  }

  @media (min-width: 1440px) {
    padding: 0 24%;
  }
`;

const Table = styled.div`
  width: 100%;
  display: grid;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  }
`;

const TableLists = styled.div`
  background-color: ${({ theme }) => theme.colors.skyBlue};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  padding: 2rem;

  @media (min-width: 768px) {
    gap: 1.5rem;
  }
`;
const TableTitle = styled(SecondaryTitle)`
  color: ${({ theme }) => theme.colors.darkBlue};
`;

const TableList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;

const ListTitle = styled(SecondaryTitle)`
  color: ${({ theme }) => theme.colors.charcoal};
  font-size: 1.6rem;

  @media (min-width: 768px) {
    font-size: 2.4rem;
  }
`;
const ListItems = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.8rem;

  li {
    line-height: 1.5rem;
  }

  @media (min-width: 768px) {
    gap: 1rem;

    li {
      line-height: 2.5rem;
    }
  }
`;

const FeatureTable = ({
  tableImageSrc,
  tableImageLabel,
  tableTitle,
  tableLists,
}) => {
  return (
    <PagePadding>
      <Table>
        <Image src={tableImageSrc} label={tableImageLabel} fit="cover" />
        <TableLists>
          <TableTitle>{tableTitle}</TableTitle>
          <>
            {tableLists.map((tableList, tableIndex) => (
              <TableList key={tableIndex}>
                <ListTitle>{tableList.listTitle}</ListTitle>
                <ListItems>
                  {tableList.listItems.map((listItem, itemIndex) => (
                    <BulletPoint
                      key={itemIndex}
                      containerType="li"
                      bulletColor="text"
                      bulletIconName="check"
                      bulletDescription={listItem}
                    />
                  ))}
                </ListItems>
              </TableList>
            ))}
          </>
        </TableLists>
      </Table>
    </PagePadding>
  );
};

FeatureTable.propTypes = {
  tableImageSrc: PropTypes.string.isRequired, // Image source is a required string
  tableImageLabel: PropTypes.string.isRequired, // Image label is a required string
  tableTitle: PropTypes.string.isRequired, // Table title is a required string
  tableLists: PropTypes.arrayOf(
    PropTypes.shape({
      listTitle: PropTypes.string.isRequired, // Each list has a title as a required string
      listItems: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired, // Array of required strings for list items
    })
  ).isRequired, // Array of table lists, where each item follows the defined shape
};
export default FeatureTable;
