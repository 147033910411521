const partnersConfig = {
  headlineSection: {
    pageTitle: 'Partnerships',
    pageSubitle: 'Our partners who help make TigerDC possible.',
  },
  logoSection: {
    logos: [
      {
        bgColor: 'skyBlue',
        titleColor: 'text',
        containerTitle: 'General Contractor',
        logos: [
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/holder-logo-w/s.webp',
            imgLabel: 'Holder Logo',
          },
        ],
      },
      {
        bgColor: 'lightBlue',
        titleColor: 'offWhite',
        containerTitle: 'MEP',
        logos: [
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/AlfaTech-w/m.webp',
            imgLabel: 'AlfaTech Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/ATPD-w/m.webp',
            imgLabel: 'ATPD Logo',
          },
        ],
      },
      {
        bgColor: 'slate',
        titleColor: 'offWhite',
        containerTitle: 'OEM',
        logos: [
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/Super%20Micro-w/m.webp',
            imgLabel: 'Super Micro Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/Intel-w/m.webp',
            imgLabel: 'Intel Logo',
          },
        ],
      },
      {
        bgColor: 'darkBlue',
        titleColor: 'offWhite',
        containerTitle: 'Banks',
        logos: [
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/MUFG-w/m.webp',
            imgLabel: 'MUFG Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/Natxis-w/m.webp',
            imgLabel: 'Natxis Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/Societe%20Generale-w/m.webp',
            imgLabel: 'Societe Generale Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/Digital%20Bridge-w/m.webp',
            imgLabel: 'Digital Bridge Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/Blackstone-w/m.webp',
            imgLabel: 'Blackstone Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/Ares-w/m.webp',
            imgLabel: 'Ares Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/Apollo-w/m.webp',
            imgLabel: 'Apollo Logo',
          },
          {
            imgSrc:
              'https://storage.googleapis.com/tiger-dc-web/partner-logos/Citi-w/m.webp',
            imgLabel: 'Citi Logo',
          },
        ],
      },

      // {
      //   bgColor: 'slate',
      //   titleColor: 'offWhite',
      //   containerTitle: 'Cooling Solutions Provider',
      //   logos: [
      //     {
      //       imgSrc:
      //         'https://storage.googleapis.com/tiger-dc-web/partner-logos/Accelsius-w/m.webp',
      //       imgLabel: 'Accelsius Logo',
      //     },
      //     {
      //       imgSrc:
      //         'https://storage.googleapis.com/tiger-dc-web/partner-logos/Opticool-w/m.webp',
      //       imgLabel: 'Opticool Logo',
      //     },
      //     {
      //       imgSrc:
      //         'https://storage.googleapis.com/tiger-dc-web/partner-logos/ECS-w/m.webp',
      //       imgLabel: 'ECS Logo',
      //     },
      //   ],
      // },
    ],
  },
};
export default partnersConfig;
