import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import useScrollLock from '../../hooks/useScrollLock';
import ImageDots from './ImageDots';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.overlay};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 25px;
  left: 15px;
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.background};
  font-size: ${({ theme }) => theme.fontSizes.medium};

  @media (min-width: 768px) {
    top: 20px;
    left: 20px;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: ${({ currentIndex }) => `translateX(-${currentIndex * 100}%)`};
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-height: 80vh;
  overflow: hidden;
  width: 100vw;
  max-width: 100%;

  @media (min-width: 768px) {
    width: 80vw;
    max-width: 900px;
  }
`;

const FullscreenImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  flex-shrink: 0;
  cursor: pointer;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 40px;
  width: 100%;
  padding-left: 10px;
`;

const ImageTitle = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 400;
  line-height: 1.2;

  @media (min-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`;

const Arrows = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 10;
`;

const ArrowButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: 0 10px;
  opacity: 0.7;
  background: ${({ theme }) => theme.colors.text};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0 20px;
  display: block;

  @media (max-width: 768px) {
    display: none;
  }

  &:hover {
    opacity: 1;
  }
`;

const SlideIndicator = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: 5px 10px;
  border-radius: 5px;

  @media (min-width: 768px) {
    top: 30px;
    right: 30px;
  }
`;

/**
 * Fullscreen overlay for image slider.
 * @param {string} imageTitle - The title of the image.
 * @param {string[]} images - The array of image URLs.
 * @param {number} currentIndex - The current index of the image.
 * @param {function} onClose - The function to close the overlay.
 * @param {function} onPrev - The function to go to the previous image.
 * @param {function} onNext - The function to go to the next image.
 * @param {function} goToSlide - The function to go to a specific index.
 */
const FullscreenOverlay = ({
  imageTitle,
  images,
  currentIndex,
  onClose,
  onPrev,
  onNext,
  goToSlide,
}) => {
  // Lock scroll on fullscreen overlay
  useScrollLock(true);

  return (
    <Overlay>
      <CloseButton onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </CloseButton>
      <Arrows>
        <ArrowButton onClick={onPrev}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </ArrowButton>
        <ArrowButton onClick={onNext}>
          <FontAwesomeIcon icon={faChevronRight} />
        </ArrowButton>
      </Arrows>
      <ImageContainer>
        <ImageWrapper currentIndex={currentIndex}>
          {images.map((img, index) => (
            <FullscreenImage key={index} src={img} alt={`Slide ${index + 1}`} />
          ))}
        </ImageWrapper>
        <TitleWrapper>
          {imageTitle && <ImageTitle>{imageTitle}</ImageTitle>}
        </TitleWrapper>
      </ImageContainer>
      <ImageDots
        images={images}
        currentIndex={currentIndex}
        goToSlide={goToSlide}
      />
      <SlideIndicator>
        {currentIndex + 1} / {images.length}
      </SlideIndicator>
    </Overlay>
  );
};

FullscreenOverlay.propTypes = {
  image: PropTypes.string.isRequired,
  imageTitle: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentIndex: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  goToSlide: PropTypes.func.isRequired,
};

export default FullscreenOverlay;
