import Styled from '@emotion/styled';
import TableSection from './TableSection';
import HeadlineSection from '../HeadlineSection';
import coolingConfig from './config';

const SectionWrapper = Styled.div`
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  
  padding: 0 6%;
  padding-bottom: 2rem;


  @media (min-width: 768px) {
    padding: 0 12%;
    padding-bottom: 6rem;
  }

  @media (min-width: 1440px) {
    padding: 0 24%;
    padding-bottom: 6rem;
  }
`;

const ComparisonSection = () => {
  const {
    sectionTitle,
    sectionSubtitle,
    headerLeft,
    headerCenter,
    headerRight,
    headerFarRight,
    tableItems,
    headLeftBgColor,
    headCenterBgColor,
    headRightBgColor,
    headFarRightBgColor,
    cellTextColor,
    columnLeftBgColor,
    columnCenterBgColor,
    columnRightBgColor,
    columnFarRightBgColor,
  } = coolingConfig.comparisonSection;
  return (
    <SectionWrapper>
      <HeadlineSection
        titleType="secondary"
        titleWidth={100}
        subtitleWidth={100}
        paddingPreset="insidePage"
        titleColor="darkBlue"
        subtitleColor="text"
        sectionTitle={sectionTitle}
        sectionSubtitle={sectionSubtitle}
      />
      <TableSection
        headerLeft={headerLeft}
        headerCenter={headerCenter}
        headerRight={headerRight}
        headerFarRight={headerFarRight}
        tableItems={tableItems}
        headLeftBgColor={headLeftBgColor}
        headCenterBgColor={headCenterBgColor}
        headRightBgColor={headRightBgColor}
        headFarRightBgColor={headFarRightBgColor}
        cellTextColor={cellTextColor}
        columnLeftBgColor={columnLeftBgColor}
        columnCenterBgColor={columnCenterBgColor}
        columnRightBgColor={columnRightBgColor}
        columnFarRightBgColor={columnFarRightBgColor}
      />
    </SectionWrapper>
  );
};
export default ComparisonSection;
