import Styled from '@emotion/styled';
import HeadlineSection from '../HeadlineSection';
import GoogleMap from '../Map';
import homePageConfig from './config';

const SectionWrapper = Styled.div`
  background-color: ${({ theme }) => theme.colors.skyBlue};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 6%;

  @media (min-width: 768px) {
    padding: 0 12%;
  }

  @media (min-width: 1440px) {
    padding: 0 24%;
  }
`;

const MapWrapper = Styled.div`
  width: 100%;
  height: 30rem; 
  padding-bottom: 2rem;

  @media (min-width: 768px) {
    width: 100%;
    height: 50rem; 
    max-width: 100%;
    padding-bottom: 6rem;
  }
`;

const LocationsSection = () => {
  const { sectionTitle, sectionSubtitle, locations } =
    homePageConfig.locationsSection;
  return (
    <SectionWrapper>
      <HeadlineSection
        titleType="primary"
        titleWidth={100}
        subtitleWidth={100}
        paddingPreset="insidePage"
        titleColor="darkBlue"
        subtitleColor="text"
        sectionTitle={sectionTitle}
        sectionSubtitle={sectionSubtitle}
      />
      <MapWrapper>
        <GoogleMap locations={locations} />
      </MapWrapper>
    </SectionWrapper>
  );
};

export default LocationsSection;
