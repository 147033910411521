import Styled from '@emotion/styled';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SiteMap from '../components/SiteMap';

import WelcomeSection from '../components/CoolingPage/WelcomeSection';
import ComparisonSection from '../components/CoolingPage/ComparisonSection';
import FutureProofSection from '../components/CoolingPage/FutureProofSection';
import SolutionsSection from '../components/CoolingPage/SolutionsSection';

const CoolingWrapper = Styled.div``;

const Cooling = () => (
  <CoolingWrapper>
    <Header />
    <WelcomeSection />
    <ComparisonSection />
    <FutureProofSection />
    <SolutionsSection />
    <SiteMap />
    <Footer />
  </CoolingWrapper>
);

export default Cooling;
