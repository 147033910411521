import Styled from '@emotion/styled';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContext'; // Import GlobalContext

/* Mobile menu styling when hamburger is open */
const NavLinks = Styled.nav`
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkBlue};
  background-color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.large};
  box-sizing: border-box;
  position: absolute;
  top: 8rem;
  left: 0;
  height: 100vh;
  width: 100%;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-end;
  gap: 4rem;
  padding: 3rem 2rem;


  a {
    color: ${({ theme }) => theme.colors.darkBlue};
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-optical-sizing: auto;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-style: normal;
    text-decoration: none;
    padding: 0.6rem;
    transition-property: background-color, color;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }

  a.active {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.greenAccent}
  }

  @media (min-width: 768px) {
    border: none;
    background-color: transparent;
    font-size: ${({ theme }) => theme.fontSizes.regular};
    position: static;
    height: auto;
    width: auto;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0;
    
    a:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.greenAccent};
    }
  }

  @media (min-width: 1440px) {
    gap: 4rem;
    padding: 0;
  }
`;

/**
 * NavMenu is a component that renders a list of links to the main sections of
 * the TigerDC website. It is intended to be used in the Header component.
 *
 * @returns {ReactElement} A ReactElement to be rendered in the Header.
 */

const NavMenu = () => {
  const { menuOpen, setMenuOpen } = useContext(GlobalContext); // Access context

  const handleLinkClick = () => {
    setMenuOpen(false); // Close the menu
  };

  return (
    <NavLinks isOpen={menuOpen}>
      <NavLink to="/team" onClick={handleLinkClick}>
        Team
      </NavLink>
      <NavLink to="/approach" onClick={handleLinkClick}>
        Approach
      </NavLink>
      <NavLink to="/aboutSC1" onClick={handleLinkClick}>
        SC1
      </NavLink>
      {/* <NavLink to="/sc1" onClick={handleLinkClick}>
        Data Centers
      </NavLink> */}
      <NavLink to="/cooling" onClick={handleLinkClick}>
        Cooling
      </NavLink>
      <NavLink to="/partners" onClick={handleLinkClick}>
        Partners
      </NavLink>
    </NavLinks>
  );
};

export default NavMenu;
