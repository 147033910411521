import Styled from '@emotion/styled';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SiteMap from '../components/SiteMap';

import HeroSection from '../components/HomePage/HeroSection';
import SC1Section from '../components/HomePage/SC1Section';
import TeamSection from '../components/HomePage/TeamSection';
import CoolingSection from '../components/HomePage/CoolingSection';
import LocationsSection from '../components/HomePage/LocationsSection';

const HomePageWrapper = Styled.div`
  display: flex;
  flex-direction: column;
`;

const Home = () => (
  <HomePageWrapper>
    <Header />
    <HeroSection />
    <SC1Section />
    <TeamSection />
    <CoolingSection />
    <LocationsSection />
    <SiteMap />
    <Footer />
  </HomePageWrapper>
);

export default Home;
