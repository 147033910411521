import Styled from '@emotion/styled';

const SecondaryTitle = Styled.div`

  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  letter-spacing: ${({ theme }) => theme.letterSpacing.bodySmall};  
  cursor: default;
  
  a {
    transition: color 0.2s ease-in-out;
  }
  a: hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.lightBlue};
  }

  @media (min-width: 768px) {
    letter-spacing: ${({ theme }) => theme.letterSpacing.bodyRegular};
    font-size: ${({ theme }) => theme.fontSizes.large};
    white-space: pre-line;
  }

  @media (min-width: 1440px) {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

export default SecondaryTitle;
