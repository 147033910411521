import Styled from '@emotion/styled';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContext';

const FooterStyled = Styled.footer`
  border-top: 1px solid ${({ theme }) => theme.colors.darkBlue};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: 1.4rem;
  box-sizing: border-box;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  z-index: 998;
  height: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 6%;

  @media (min-width: 768px) {
    padding: 0 12%;
  }

  @media (min-width: 1440px) {
    padding: 0 24%;
  }
`;

const Footer = () => {
  const { setMenuOpen } = useContext(GlobalContext);
  return (
    <FooterStyled>
      <Link to="/" onClick={() => setMenuOpen(false)}>
        © TigerDC 2024
      </Link>
    </FooterStyled>
  );
};

export default Footer;
