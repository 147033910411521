import styled from '@emotion/styled';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SiteMap from '../components/SiteMap';
import ProfileCard from '../components/ProfileCard';
import teamConfig from '../components/TeamPage/config';

const TeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 0 6%;
`;

const HeadlineSection = styled.section`
  text-align: center;
  margin-top: 8rem;
  padding: 2rem 1rem;

  @media (min-width: 768px) {
    padding: 4rem 2rem;
  }

  @media (min-width: 1440px) {
    padding: 6rem 3rem;
  }
`;

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 4rem;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin: 1rem 0;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 8rem;
    margin: 1rem 0;
  }
`;

const Subtitle = styled.p`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.text};
  margin: 0.5rem 0;

  @media (min-width: 768px) {
    font-size: 2.5rem;
    margin: 1rem 0;
  }
`;

const ProfileCardsArea = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 4rem;
  box-sizing: border-box;
  max-width: 1200px;
  row-gap: 4rem;
  column-gap: 6rem;
`;

const Team = () => {
  const { sectionTitle, sectionSubtitle } = teamConfig.headlineSection;
  const { cards } = teamConfig.teamSection;

  return (
    <>
      <Header />
      <TeamWrapper>
        <HeadlineSection>
          <Title>{sectionTitle}</Title>
          <Subtitle>{sectionSubtitle}</Subtitle>
        </HeadlineSection>
        <ProfileCardsArea>
          {cards.map((card, index) => (
            <ProfileCard
              key={index}
              profileName={card.profileName}
              profilePosition={card.profilePosition}
              profileDescription={card.profileDescription}
              avatarSrc={card.profileImage}
            />
          ))}
        </ProfileCardsArea>
      </TeamWrapper>
      <SiteMap />
      <Footer />
    </>
  );
};

export default Team;
