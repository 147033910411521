import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const HeadlineSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 8rem;
  padding: 2rem 1rem;

  @media (min-width: 768px) {
    padding: 4rem 2rem;
  }

  @media (min-width: 1440px) {
    padding: 6rem 3rem;
  }
`;

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 4rem;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin: 1rem 0;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 8rem;
    margin: 1rem 0;
  }
`;

const Subtitle = styled.p`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.text};
  margin: 0.5rem 0;
  max-width: 650px;

  @media (min-width: 768px) {
    font-size: 2.5rem;
    margin: 1rem 0;
  }
`;

const PageHeader = ({ title, subtitle }) => (
  <HeadlineSection>
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </HeadlineSection>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default PageHeader;
