import PropTypes from 'prop-types';
import BulletPoint from '../BulletPoint';
import Styled from '@emotion/styled';

const Bullets = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.2rem;
`;

const DataCenterFeatureDetail = ({ featureIconName, featureDescriptions }) => {
  return (
    <Bullets>
      {featureDescriptions.map((featureDescription, index) => (
        <BulletPoint
          key={index}
          bulletColor="lightBlue"
          bulletIconName={featureIconName}
          bulletDescription={featureDescription}
        />
      ))}
    </Bullets>
  );
};

DataCenterFeatureDetail.propTypes = {
  featureIconName: PropTypes.string.isRequired,
  featureDescriptions: PropTypes.array,
};
export default DataCenterFeatureDetail;
