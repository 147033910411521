import PropTypes from 'prop-types';
import Styled from '@emotion/styled';
import Subtitle from '../styles/Subtitle';
import Icon from './Icon';

/**
 * @function Bullet Point
 * @description A React component that contains the styling and elements for a bulleted line.
 * @param bulletColor - Set the color of the bullet point and line(s) after. Optional.
 * @param bulletIconName - Set the icon name of the bullet itself, names from Fontawesome database. Required.
 * @param bulletDescription - Set the content of the bulleted line in the form of a string. Required.
 * @returns {ReactElement} A React component that renders a bullet point and associated sentence(s) with a custom color and icon.
 */

const BulletPoint = ({
  containerType,
  bulletColor,
  bulletIconName,
  bulletDescription,
}) => {
  const Wrapper = Styled(Subtitle)`
    color: ${({ theme }) => theme.colors[bulletColor]};
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;

    @media (min-width: 768px) {
      gap: 2rem;
    }
`;

  const IconWrapper = Styled.span`
    display: inline;
`;
  return (
    <Wrapper as={containerType}>
      <IconWrapper>
        <Icon iconName={bulletIconName} size="1x" iconType="solid" />
      </IconWrapper>
      <p>{bulletDescription}</p>
    </Wrapper>
  );
};

BulletPoint.propTypes = {
  containerType: PropTypes.string,
  bulletColor: PropTypes.string,
  bulletIconName: PropTypes.string.isRequired,
  bulletDescription: PropTypes.string.isRequired,
};
export default BulletPoint;
