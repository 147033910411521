import Styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const TableItem = Styled.tbody`
  height: 6rem;
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  font-weight: ${({ theme }) => theme.fontWeights.regular};

  @media (min-width: 768px) {
    height: 8rem;
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }
`;

const RowWrapper = Styled.tr`
  height: inherit;
`;

const ItemCellTemplate = Styled.th`
  color: ${({ theme, cellTextColor }) => theme.colors[cellTextColor]};
    p {
      color: ${({ theme, cellTextColor }) => theme.colors[cellTextColor]};
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
`;

const ItemCellLeft = Styled(ItemCellTemplate)`
  background-color: ${({ theme, columnLeftBgColor }) => theme.colors[columnLeftBgColor]};
  text-align: left;
  vertical-align: middle;
  padding: 0 1rem;

  @media (min-width: 768px) {
    padding: 0 2rem;
  }
`;

const ItemCellCenter = Styled(ItemCellTemplate)`
  border-left: 1px solid ${({ theme }) => theme.colors.white};
  border-right: 1px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, columnCenterBgColor }) => theme.colors[columnCenterBgColor]};
  text-align: center;
  vertical-align: middle;
  padding: 0 1rem;

  @media (min-width: 768px) {
    width: 20%;
    padding: 0 2rem;
  }
`;

const ItemCellRight = Styled(ItemCellTemplate)`
  background-color: ${({ theme, columnRightBgColor }) => theme.colors[columnRightBgColor]};
  text-align: center;
  vertical-align: middle;
  padding: 0 1rem;

  @media (min-width: 768px) {
    width: 20%;
    padding: 0 2rem;
  }
`;

const ItemCellFarRight = Styled(ItemCellTemplate)`
  border-left: 1px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, columnFarRightBgColor }) => theme.colors[columnFarRightBgColor]};
  text-align: center;
  vertical-align: middle;
  padding: 0 1rem;

  @media (min-width: 768px) {
    width: 20%;
    padding: 0 2rem;
  }
`;

const TableItems = ({
  cellTextColor,
  columnLeft,
  columnCenter,
  columnRight,
  columnFarRight,
  iconCenterName,
  iconRightName,
  iconFarRightName,
  shouldRenderFarRight,
  columnLeftBgColor,
  columnCenterBgColor,
  columnRightBgColor,
  columnFarRightBgColor,
}) => {
  return (
    <TableItem>
      <RowWrapper>
        <ItemCellLeft
          cellTextColor={cellTextColor}
          columnLeftBgColor={columnLeftBgColor}
        >
          {columnLeft}
        </ItemCellLeft>
        <ItemCellCenter
          cellTextColor={cellTextColor}
          columnCenterBgColor={columnCenterBgColor}
        >
          {columnCenter && columnCenter.trim() ? (
            <p>{columnCenter}</p>
          ) : (
            <Icon iconName={iconCenterName} size="1x" iconType="solid" />
          )}
        </ItemCellCenter>
        <ItemCellRight
          cellTextColor={cellTextColor}
          columnRightBgColor={columnRightBgColor}
        >
          {columnRight && columnRight.trim() ? (
            <p>{columnRight}</p>
          ) : (
            <Icon iconName={iconRightName} size="1x" iconType="solid" />
          )}
        </ItemCellRight>
        {shouldRenderFarRight && (
          <ItemCellFarRight
            cellTextColor={cellTextColor}
            columnFarRightBgColor={columnFarRightBgColor}
          >
            {columnFarRight && columnFarRight.trim() ? (
              <p>{columnFarRight}</p>
            ) : (
              <Icon iconName={iconFarRightName} size="1x" iconType="solid" />
            )}
          </ItemCellFarRight>
        )}
      </RowWrapper>
    </TableItem>
  );
};

TableItems.propTypes = {
  cellTextColor: PropTypes.string,
  columnLeft: PropTypes.string,
  columnCenter: PropTypes.string,
  columnRight: PropTypes.string,
  columnFarRight: PropTypes.string,
  iconCenterName: PropTypes.string,
  iconRightName: PropTypes.string,
  iconFarRightName: PropTypes.string,
  shouldRenderFarRight: PropTypes.string,
  columnLeftBgColor: PropTypes.string,
  columnCenterBgColor: PropTypes.string,
  columnRightBgColor: PropTypes.string,
  columnFarRightBgColor: PropTypes.string,
};

export default TableItems;
