import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const DotsWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  width: fit-content;
  z-index: 20;
  padding: 5px;
  border-radius: 10px;
`;

const DotsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  transition: transform 0.3s ease-in-out;
`;

const Dot = styled.div`
  width: ${({ isEdge }) => (isEdge ? '6px' : '8px')};
  height: ${({ isEdge }) => (isEdge ? '6px' : '8px')};
  margin: 0 5px;
  border-radius: 50%;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.activeDot : theme.colors.inactiveDot};
  cursor: pointer;

  @media (min-width: 768px) {
    width: ${({ isEdge }) => (isEdge ? '8px' : '10px')};
    height: ${({ isEdge }) => (isEdge ? '8px' : '10px')};
  }
`;

/**
 * Render a row of dots to represent the slides in the image slider. The first
 * and last dots are edge dots, and they are slightly smaller than the middle dots.
 * The current slide is highlighted with a different color.
 * @param {Array<string>} images - An array of image URLs
 * @param {number} currentIndex - The current index of the slide
 * @param {function(number)} goToSlide - A function to call when a dot is clicked
 * @returns {ReactElement}
 */
const ImageDots = ({ images, currentIndex, goToSlide }) => {
  const totalDots = images.length;
  const maxDotsToShow = 5; // Adjusted to show only 5 dots
  let startDotIndex = 0;

  if (totalDots > maxDotsToShow) {
    if (currentIndex < Math.floor(maxDotsToShow / 2)) {
      startDotIndex = 0;
    } else if (currentIndex > totalDots - Math.ceil(maxDotsToShow / 2)) {
      startDotIndex = totalDots - maxDotsToShow;
    } else {
      startDotIndex = currentIndex - Math.floor(maxDotsToShow / 2);
    }
  }

  const dotsToShow = images.slice(startDotIndex, startDotIndex + maxDotsToShow);

  return (
    <DotsWrapper>
      <DotsContainer>
        {dotsToShow.map((_, index) => {
          const actualIndex = startDotIndex + index;
          const isEdge =
            (index === 0 && startDotIndex > 0) ||
            (index === dotsToShow.length - 1 &&
              startDotIndex + maxDotsToShow < totalDots);

          return (
            <Dot
              key={actualIndex}
              active={actualIndex === currentIndex}
              isEdge={isEdge}
              onClick={() => goToSlide(actualIndex)}
            />
          );
        })}
      </DotsContainer>
    </DotsWrapper>
  );
};

ImageDots.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentIndex: PropTypes.number.isRequired,
  goToSlide: PropTypes.func.isRequired,
};

export default ImageDots;
