import { Link } from 'react-router-dom';
import Styled from '@emotion/styled';
import Subtitle from '../../styles/Subtitle';
import HeadlineSection from '../HeadlineSection';
import homePageConfig from './config';

const SectionWrapper = Styled.div`
  background-color: ${({ theme }) => theme.colors.darkBlue};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5% 6%;

  @media (min-width: 768px) {
    padding: 5rem 12%;  
  }

  @media (min-width: 1440px) {
    padding: 5rem 24%;  
  }
`;

const MoreAbout = Styled(Subtitle)`
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  display: flex;
  align-items: center;  
  justify-content: flex-end;  
  padding-top: 2rem;  
  padding-bottom: 0;

  @media (min-width: 768px) {
    padding-top: 3rem;  
  }
`;

const MoreLink = Styled(Link)`
  color: ${({ theme }) => theme.colors.silver};  
  text-decoration: none;
`;

const TeamSection = () => {
  const { sectionTitle, sectionSubtitle, moreLink } =
    homePageConfig.teamSection;

  return (
    <SectionWrapper>
      <HeadlineSection
        titleType="primary"
        titleWidth={100}
        subtitleWidth={100}
        paddingPreset="insidePage"
        titleColor="white"
        subtitleColor="silver"
        sectionTitle={sectionTitle}
        sectionSubtitle={sectionSubtitle}
      />
      <MoreAbout>
        <MoreLink to={moreLink.path}>{moreLink.text}</MoreLink>
      </MoreAbout>
    </SectionWrapper>
  );
};

export default TeamSection;
