import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Icon from '../Icon';
import SecondaryTitle from '../../styles/SecondaryTitle';

const LogosContainer = styled.div`
  background-color: ${({ theme, bgColor }) => theme.colors[bgColor]};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem 0;

  @media (min-width: 768px) {
    gap: 2rem;
    padding: 2rem 0;
  }
`;

const ContainerTitle = styled(SecondaryTitle)`
  color: ${({ theme, titleColor }) => theme.colors[titleColor]};
`;

const LogosArea = styled.div`
  width: calc(100% - 2rem);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  justify-items: center;
  align-items: center;
  gap: 6rem;
  padding: 2rem;

  @media (min-width: 768px) {
    width: calc(100% - 8rem);
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  }
`;

const LogoImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 20rem;

  img {
    box-sizing: border-box;
    width: 100%;
    height: auto;
  }
`;

const LogoFeature = ({
  bgColor = 'defaultBgColor',
  titleColor = 'defaultTitleColor',
  containerTitle,
  logos,
}) => (
  <LogosContainer bgColor={bgColor}>
    <ContainerTitle titleColor={titleColor}>{containerTitle}</ContainerTitle>
    <LogosArea>
      {logos.map((logo) => (
        <LogoImageWrapper key={logo.id || logo.imgLabel}>
          <Icon
            iconName={logo.imgLabel}
            iconLabel={logo.imgLabel}
            iconType="image"
            url={logo.imgSrc}
          />
        </LogoImageWrapper>
      ))}
    </LogosArea>
  </LogosContainer>
);

// PropTypes
LogoFeature.propTypes = {
  bgColor: PropTypes.string,
  titleColor: PropTypes.string,
  containerTitle: PropTypes.string.isRequired,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      imgLabel: PropTypes.string.isRequired,
      imgSrc: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default LogoFeature;
