import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import SecondaryTitle from '../../styles/SecondaryTitle';
import FeatureIcons from '../FeatureIcons';

const Strip = styled.div`
  background-color: ${({ theme, bgColor }) => theme.colors[bgColor]};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 2rem 6%;

  div {
    color: ${({ theme, iconTitleColor }) => theme.colors[iconTitleColor]};
  }
  div p {
    color: ${({ theme, iconDescriptionColor }) =>
      theme.colors[iconDescriptionColor]};
  }

  @media (min-width: 768px) {
    padding: 4rem 12%;
  }
  @media (min-width: 1440px) {
    padding: 4rem 24%;
  }
`;

const IconSectionHeadline = styled(SecondaryTitle)`
  color: ${({ theme, titleColor }) => theme.colors[titleColor]};
  text-align: center;
`;

const FeaturesWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  row-gap: 2rem;

  @media (min-width: 768px) {
    height: auto;
    max-width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const IconStrip = ({
  bgColor,
  iconTitleColor,
  iconDescriptionColor,
  titleColor,
  iconStripTitle,
  features,
}) => {
  return (
    <Strip
      bgColor={bgColor}
      iconTitleColor={iconTitleColor}
      iconDescriptionColor={iconDescriptionColor}
    >
      <IconSectionHeadline as="h4" titleColor={titleColor}>
        {iconStripTitle}
      </IconSectionHeadline>
      {/* <FeatureSection features={iconStripIcons} /> */}
      <FeaturesWrapper>
        {features.map((feature, index) => (
          <FeatureIcons
            key={index}
            iconSrc={feature.iconSrc}
            iconLabel={feature.iconLabel}
            iconBgColor={feature.iconBgColor}
            iconShrink={feature.iconShrink}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </FeaturesWrapper>
    </Strip>
  );
};

IconStrip.propTypes = {
  bgColor: PropTypes.string,
  iconTitleColor: PropTypes.string,
  iconDescriptionColor: PropTypes.string,
  titleColor: PropTypes.string,
  iconStripTitle: PropTypes.string,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      iconSrc: PropTypes.string.isRequired,
      iconLabel: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
};

export default IconStrip;
