import PropTypes from 'prop-types';
import Styled from '@emotion/styled';
import SecondaryTitle from '../../styles/SecondaryTitle';
import Subtitle from '../../styles/Subtitle';
import FeatureIcons from '../FeatureIcons';

const ApproachStageCard = ({
  cardBgColor,
  primaryTextColor,
  secondaryTextColor,
  iconSrc,
  iconLabel,
  iconBgColor,
  iconShrink,
  stageNumber,
  stageTitle,
  featureTexts,
}) => {
  const Card = Styled.div`
    background-color: ${({ theme }) => theme.colors[cardBgColor]};
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 6%;

    @media (min-width: 768px) {
        padding: 4rem 12%;
    }

    @media (min-width: 1440px) {
        padding: 4rem 24%;
    }
`;
  const StageNumber = Styled(SecondaryTitle)`
    color: ${({ theme }) => theme.colors[primaryTextColor]};
    padding-top: 1rem;

    @media (min-width: 768px) {
      padding-top: 2rem;
    }
  `;
  const Title = Styled(SecondaryTitle)`
    color: ${({ theme }) => theme.colors[primaryTextColor]};
    padding-bottom: 2rem;

    @media (min-width: 768px) {
      padding-bottom: 4rem;
    }
`;
  const Feature = Styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
  const FeatureTitle = Styled(Subtitle)`
    color: ${({ theme }) => theme.colors[secondaryTextColor]};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    padding-bottom: 0.25rem;

    @media (min-width: 768px) {
      padding-bottom: 0;
    }
`;
  const FeatureDescription = Styled(Subtitle)`
    color: ${({ theme }) => theme.colors[secondaryTextColor]};
    width: 80%;
    padding-bottom: 1rem;

    @media (min-width: 768px) {
      padding-bottom: 2rem;
    }
`;
  return (
    <Card>
      <FeatureIcons
        iconSrc={iconSrc}
        iconLabel={iconLabel}
        iconBgColor={iconBgColor}
        iconShrink={iconShrink}
      />
      <StageNumber>{stageNumber}</StageNumber>
      <Title>{stageTitle}</Title>

      {featureTexts.map(({ featureTitle, featureDescription }, index) => (
        <Feature key={index}>
          <FeatureTitle>{featureTitle}</FeatureTitle>
          <FeatureDescription>{featureDescription}</FeatureDescription>
        </Feature>
      ))}
    </Card>
  );
};

ApproachStageCard.propTypes = {
  cardBgColor: PropTypes.string,
  primaryTextColor: PropTypes.string,
  secondaryTextColor: PropTypes.string,
  iconSrc: PropTypes.string.isRequired,
  iconLabel: PropTypes.string.isRequired,
  iconBgColor: PropTypes.string,
  iconShrink: PropTypes.number.isRequired,
  stageNumber: PropTypes.string.isRequired,
  stageTitle: PropTypes.string.isRequired,
  featureTexts: PropTypes.arrayOf(
    PropTypes.shape({
      featureTitle: PropTypes.string,
      featureDescription: PropTypes.string,
    })
  ),
};

export default ApproachStageCard;
