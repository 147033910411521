import Styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Subtitle from '../styles/Subtitle';

const ColumnWrapper = Styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 4rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ColumnGlobalStyle = Styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 2rem 6%;

  @media (min-width: 768px) {
    align-items: center;
    padding: 2rem;
  }
`;

const Column1 = Styled(ColumnGlobalStyle)`
  background-color: ${({ theme }) => theme.colors.slate};

  @media (min-width: 768px) {
    flex: 2;
  }

  @media (min-width: 768px) {
    flex:1;
  }
`;

const Column2 = Styled(ColumnGlobalStyle)`
  background-color: ${({ theme }) => theme.colors.lightBlue};

  a {
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  @media (min-width: 768px) {
    flex: 1;
  }
`;

const Column3 = Styled(ColumnGlobalStyle)`
  background-color: ${({ theme }) => theme.colors.darkBlue};

  a {
    color: ${({ theme }) => theme.colors.skyBlue};
  }

  @media (min-width: 768px) {
    flex: 1;
  }
`;

const ColumnContent = Styled.div``;

const Address = Styled.span`
  color: ${({ theme }) => theme.colors.charcoal};
`;

const ColumnTitle = Styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  letter-spacing: -0.05rem;
  color: ${({ theme }) => theme.colors.white};
  padding-bottom: 1.4rem;

  @media (min-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.large};
    padding-bottom: 2rem;
  }
`;

const LinkWrapper = Styled(Subtitle)`
  font-size: ${({ theme }) => theme.fontSizes.regular};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;

  @media (min-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    gap: 0.5rem; 
  }
`;

const SiteMap = () => (
  <ColumnWrapper>
    <Column1>
      <ColumnContent>
        <ColumnTitle>Contact</ColumnTitle>
        <LinkWrapper>
          <Address>1540 Broadway, Ste 1010</Address>
          <Address>New York, NY 10036</Address>
          <Address>info@vcvdigital.com</Address>
        </LinkWrapper>
      </ColumnContent>
    </Column1>
    <Column2>
      <ColumnContent>
        <ColumnTitle>Services</ColumnTitle>
        <LinkWrapper>
          <Link to="/aboutsc1">SC1</Link>
          {/* <Link to="/datacenters">Data Centers</Link> */}
          <Link to="/cooling">Cooling</Link>
        </LinkWrapper>
      </ColumnContent>
    </Column2>
    <Column3>
      <ColumnContent>
        <ColumnTitle>TigerAI</ColumnTitle>
        <LinkWrapper>
          <Link to="/team">Team</Link>
          <Link to="/approach">Approach</Link>
          <Link to="/partners">Partners</Link>
        </LinkWrapper>
      </ColumnContent>
    </Column3>
  </ColumnWrapper>
);

export default SiteMap;
