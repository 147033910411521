import PropTypes from 'prop-types';
import Styled from '@emotion/styled';
import Subtitle from '../../styles/Subtitle';
import SecondaryTitle from '../../styles/SecondaryTitle';
import Image from '../Image';
import BulletPoint from '../BulletPoint';

const Solution = Styled.div`
  background-color: ${({ theme, bgColor }) => theme.colors[bgColor]};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    height: 32rem;
    flex-direction: row;
  }
`;

const SolutionImageWrapper = Styled.div`
  flex: 1;
  
  img {
   height: 16rem;
  }

  @media (min-width: 1024px) {
    img {
      height: 100%;
    }
  }
`;

const SolutionInfoWrapper = Styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.4rem;
  padding: 2rem;
  padding-top: 1rem;

  @media (min-width: 1024px) {
    gap: 1rem;
    padding: 2rem;
  }
`;

const InfoTitle = Styled(SecondaryTitle)`
  color: ${({ theme, solutionTextColor }) => theme.colors[solutionTextColor]};
  align-self: center;

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
  }

  @media (min-width: 1024px) {
    align-self: inherit;
  }
`;

const InfoSubtitle = Styled(Subtitle)`
  color: ${({ theme, solutionTextColor }) => theme.colors[solutionTextColor]};
  align-self: center;
  text-align: center;
  width: 80%;
  margin-bottom: 1rem;

  @media (min-width: 1024px) {
    align-self: inherit;
    text-align: left;
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const SolutionsFeatureCard = ({
  imgSrc,
  imgLabel,
  imgFit,
  infoTitle,
  infoSubtitle,
  bgColor,
  featureIconName,
  solutionTextColor,
  featureTexts,
  featureTextColor,
}) => {
  return (
    <Solution bgColor={bgColor}>
      <SolutionImageWrapper>
        <Image src={imgSrc} label={imgLabel} fit={imgFit} />
      </SolutionImageWrapper>
      <SolutionInfoWrapper>
        <InfoTitle solutionTextColor={solutionTextColor}>{infoTitle}</InfoTitle>
        <InfoSubtitle solutionTextColor={solutionTextColor}>
          {infoSubtitle}
        </InfoSubtitle>
        {featureTexts.map((featureText, index) => (
          <BulletPoint
            key={index}
            bulletColor={featureTextColor}
            bulletIconName={featureIconName}
            bulletDescription={featureText}
          />
        ))}
      </SolutionInfoWrapper>
    </Solution>
  );
};
SolutionsFeatureCard.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgLabel: PropTypes.string.isRequired,
  imgFit: PropTypes.string.isRequired,
  infoTitle: PropTypes.string,
  infoSubtitle: PropTypes.string,
  bgColor: PropTypes.string,
  featureIconName: PropTypes.string.isRequired,
  featureTextColor: PropTypes.string,
  solutionTextColor: PropTypes.string,
  featureTexts: PropTypes.array.isRequired,
};

export default SolutionsFeatureCard;
