import Styled from '@emotion/styled';

const Subtitle = Styled.p`
  font-family: ${({ theme }) => theme.fonts.secondary};
  font-optical-sizing: auto;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-style: normal;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.small};
  letter-spacing: ${({ theme }) => theme.letterSpacing.body};
  line-height: ${({ theme }) => theme.lineHeight.bodySmall};
  margin: 0;
  padding: 0;
  hypens: none;
  cursor: default;

  a {
    transition: color 0.2s ease-in-out;
  }
  a: hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.greenAccent};
  }

  @media (min-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    line-height: ${({ theme }) => theme.lineHeight.bodyRegular};
    white-space: pre-line;
  }

  @media (min-width: 1440px) {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    line-height: 3rem;
  }
`;

export default Subtitle;
