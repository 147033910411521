const dataCentersConfig = {
  global: {
    submenuSection: {
      menuLabels: [
        {
          link: '/nc1',
          label: 'NC1',
        },
        {
          link: '/nd1',
          label: 'ND1',
        },
        {
          link: '/sc1',
          label: 'SC1',
        },
        {
          link: '/sd1',
          label: 'SD1',
        },
        {
          link: '/tx1',
          label: 'TX1',
        },
      ],
    },
  },
  sc1: {
    headlineSection: {
      pageTitle: 'SC1 AIDC',
      pageSubtitle:
        'A 100+ MW project in South Carolina is underway, targeting 2026 \n completion, with 600 MW capacity secured in the AIDC pipeline.',
      subtitleWidth: 100,
    },
    imageSlider: {
      sliderImages: [
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter1/l.webp',
          title: 'Datacenter 1',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter2/l.webp',
          title: 'Datacenter 2',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter3/l.webp',
          title: '',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter4/l.webp',
          title: '',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter5/l.webp',
          title: '',
        },
      ],
    },
    specs: [
      {
        featureTitle: 'Power',
        featureIconName: 'check',
        featureDescriptions: [
          '50 MW approved now',
          'Additional 60 MW ready by 2025',
        ],
      },
      {
        featureTitle: 'Facility',
        featureIconName: 'check',
        featureDescriptions: ['Existing 100 KV / 34.5 KV substation'],
      },
      {
        featureTitle: 'Land',
        featureIconName: 'check',
        featureDescriptions: ['44 acres lease to purchase'],
      },
    ],
  },
  nc1: {
    headlineSection: {
      pageTitle: 'NC1 AIDC',
      pageSubtitle:
        'Our first data center in North Carolina, \n to be completed by 2026.',
      subtitleWidth: 70,
    },
    imageSlider: {
      sliderImages: [
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter1/l.webp',
          title: 'Datacenter 1',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter2/l.webp',
          title: 'Datacenter 2',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter3/l.webp',
          title: '',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter4/l.webp',
          title: '',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter5/l.webp',
          title: '',
        },
      ],
    },
    specs: [
      {
        featureTitle: 'Power',
        featureIconName: 'check',
        featureDescriptions: [
          '45 MW approved and ready by 2026',
          '50 MW under review by Duke Energy',
        ],
      },
      {
        featureTitle: 'Facility',
        featureIconName: 'check',
        featureDescriptions: [
          '24.9 KV substation upgrade completed by Q3 2026',
          '100 KV transmission line',
        ],
      },
      {
        featureTitle: 'Land',
        featureIconName: 'check',
        featureDescriptions: ['Existing warehouse or out parcels'],
      },
    ],
  },
  tx1: {
    headlineSection: {
      pageTitle: 'TX1 AIDC',
      pageSubtitle:
        'Our first data center in Texas, \n currently under development.',
      subtitleWidth: 55,
    },
    imageSlider: {
      sliderImages: [
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter1/l.webp',
          title: 'Datacenter 1',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter2/l.webp',
          title: 'Datacenter 2',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter3/l.webp',
          title: '',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter4/l.webp',
          title: '',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter5/l.webp',
          title: '',
        },
      ],
    },
    specs: [
      {
        featureTitle: 'Power',
        featureIconName: 'check',
        featureDescriptions: [
          '150 MW approved by ERCOT',
          'Interconnected to wind farm & transmission grid',
        ],
      },
      {
        featureTitle: 'Facility',
        featureIconName: 'check',
        featureDescriptions: [
          'Onsite 34.5 KV wind farm substation ready',
          '138 KV transmission line',
        ],
      },
      {
        featureTitle: 'Land',
        featureIconName: 'check',
        featureDescriptions: [
          '16–20 acres with long-term lease',
          'Additional land available as needed',
        ],
      },
    ],
  },
  nd1: {
    headlineSection: {
      pageTitle: 'ND1 AIDC',
      pageSubtitle:
        'Our first data center in North Dakota, \n currently operational with upgrades underway for 2025.',
      subtitleWidth: 85,
    },
    imageSlider: {
      sliderImages: [
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter1/l.webp',
          title: 'Datacenter 1',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter2/l.webp',
          title: 'Datacenter 2',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter3/l.webp',
          title: '',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter4/l.webp',
          title: '',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter5/l.webp',
          title: '',
        },
      ],
    },
    specs: [
      {
        featureTitle: 'Power',
        featureIconName: 'check',
        featureDescriptions: [
          '50 MW now, 150 MW by 2024, 300 MW by 2025',
          'Wind & Natural Gas',
        ],
      },
      {
        featureTitle: 'Facility',
        featureIconName: 'check',
        featureDescriptions: [
          'Existing 34.5 KV substation located across the street',
          '345 KV transmission line',
        ],
      },
      {
        featureTitle: 'Land',
        featureIconName: 'check',
        featureDescriptions: [
          '15 acres lease signed',
          'Additional land available as needed',
        ],
      },
    ],
  },
  sd1: {
    headlineSection: {
      pageTitle: 'SD1 AIDC',
      pageSubtitle:
        'Our first data center in South Dakota, \n currently operational.',
      subtitleWidth: 70,
    },
    imageSlider: {
      sliderImages: [
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter1/l.webp',
          title: 'Datacenter 1',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter2/l.webp',
          title: 'Datacenter 2',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter3/l.webp',
          title: '',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter4/l.webp',
          title: '',
        },
        {
          url: 'https://storage.googleapis.com/tiger-dc-web/images/datacenter5/l.webp',
          title: '',
        },
      ],
    },
    specs: [
      {
        featureTitle: 'Power',
        featureIconName: 'check',
        featureDescriptions: ['50 MW now', '300 MW ESA Signed'],
      },
      {
        featureTitle: 'Facility',
        featureIconName: 'check',
        featureDescriptions: ['Substation ready'],
      },
    ],
  },
};
export default dataCentersConfig;
