const theme = {
  colors: {
    white: '#fff',
    black: '#000',
    darkBlue: '#1A3355',
    lightBlue: '#6E98C9',
    skyBlue: '#B3C8E0',
    offWhite: '#D9D9D9',
    silver: '#A0A0A0',
    slate: '#8996A7',
    charcoal: '#484848',
    greenAccent: '#4CAF50',

    background: '#FFFFFF',
    text: '#727272',

    activeDot: 'rgba(255, 255, 255, 1)',
    inactiveDot: 'rgba(255, 255, 255, 0.6)',
    overlay: '#000',

    scrollToTop: {
      background: '#4CAF50',
      backgroundHover: '#8996A7',
      icon: '#fff',
      iconHover: '#000',
      shadow: '#484848',
    },
  },
  fonts: {
    primary: "'Barlow', sans-serif",
    secondary: "'Inter', sans-serif",
  },
  fontSizes: {
    xsmall: '1rem',
    small: '1.2rem',
    regular: '1.6rem',
    medium: '2rem',
    large: '3.6rem',
    xlarge: '5rem',
    xxlarge: '12.8rem',
  },
  fontWeights: {
    regular: '400',
    medium: '500',
    bold: '700',
    extrabold: '800',
  },
  letterSpacing: {
    headline: '-0.5rem',
    headlineSmall: '-0.1rem',
    body: '0.01rem',
    bodySmall: '0.01rem',
  },
  lineHeight: {
    headlineSmall: '5rem',
    headlineRegular: '12rem',
    bodySmall: '1.5rem',
    bodyRegular: '3rem',
  },
};

export default theme;
