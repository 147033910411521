import styled from '@emotion/styled';
import Header from '../components/Header';
import SiteMap from '../components/SiteMap';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';
import partnersConfig from '../components/PartnersPage/config';
import LogoFeature from '../components/PartnersPage/LogoFeature';

const LogoFeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  box-sizing: border-box;
  padding: 0 6%;
  padding-bottom: 2rem;

  @media (min-width: 768px) {
    padding: 0 12%;
    padding-bottom: 6rem;
  }

  @media (min-width: 1440px) {
    padding: 0 24%;
    padding-bottom: 6rem;
  }
`;

const Partners = () => {
  const { pageTitle, pageSubitle } = partnersConfig.headlineSection;
  const { logos } = partnersConfig.logoSection;

  return (
    <>
      <Header />
      <PageHeader title={pageTitle} subtitle={pageSubitle} />
      <LogoFeatureWrapper>
        {logos.map((logoContainer, index) => (
          <LogoFeature
            key={index}
            bgColor={logoContainer.bgColor}
            titleColor={logoContainer.titleColor}
            containerTitle={logoContainer.containerTitle}
            logos={logoContainer.logos}
          />
        ))}
      </LogoFeatureWrapper>
      <SiteMap />
      <Footer />
    </>
  );
};

export default Partners;
