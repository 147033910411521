import Styled from '@emotion/styled';
import PrimaryTitle from '../../styles/PrimaryTitle';
import Subtitle from '../../styles/Subtitle';
import Image from '../Image';
import coolingConfig from './config';

const Title = Styled(PrimaryTitle)`
  color: ${({ color, theme }) => theme.colors[color] || theme.colors.text};
  display: inline;
`;

const SectionWrapper = Styled.div`
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
  justify-content: center;
  gap: 1rem;
  padding: 0 6%;
  
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 2rem;
    padding: 0;
  }
  
  @media (min-width: 1440px) {
    gap: 4rem;
  }
`;

const LeftSection = Styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    height: 100%;
    width: calc(50% - 12%);
    align-items: flex-start;
    padding-left: 12%;
  }

  @media (min-width: 1440px) {
    width: calc(50% - 24%);
    padding-left: 24%;
  }
`;

const RightSection = Styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    height: 100%;
    width: 50%;
  }
`;

const SectionTitleWrapper = Styled.div`
  width: 100%;
  max-width: 700px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;

  @media (min-width: 768px) {
    gap: 2rem;
  }
`;

const TitleContainer = Styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 0.5rem;

  @media (min-width: 768px) {
    column-gap: 1.2rem;
  }

  @media (min-width: 1440px) {
    column-gap: 2rem;
  }
`;

const SectionSubtitle = Styled(Subtitle)`
  max-width: 70%;

  @media (min-width: 768px) {
    max-width: 80%;
  }
`;

const ImageWrapper = Styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WelcomeSection = () => {
  const { sectionTitles, sectionSubtitle, image } =
    coolingConfig.welcomeSection;
  return (
    <SectionWrapper>
      <LeftSection>
        <SectionTitleWrapper>
          <TitleContainer>
            {sectionTitles.map((sectionTitle) => (
              <Title
                key={sectionTitle}
                color={
                  sectionTitle === sectionTitles[1] ? 'lightBlue' : 'darkBlue'
                }
              >
                {sectionTitle}
              </Title>
            ))}
          </TitleContainer>
          <SectionSubtitle>{sectionSubtitle}</SectionSubtitle>
        </SectionTitleWrapper>
      </LeftSection>
      <RightSection>
        <ImageWrapper>
          <Image src={image.src} alt={image.alt} fit={image.fit} />
        </ImageWrapper>
      </RightSection>
    </SectionWrapper>
  );
};

export default WelcomeSection;
