import React from 'react';
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RouteToTop from './lib/RouteToTop';
import ScrollToHash from './lib/ScrollToHash';
import { GlobalProvider } from './context/GlobalContext';
import theme from './styles/theme';
import Home from './pages/Home';
import NC1 from './pages/DataCenterProfiles/NC1';
import ND1 from './pages/DataCenterProfiles/ND1';
import SC1 from './pages/DataCenterProfiles/SC1';
import SD1 from './pages/DataCenterProfiles/SD1';
import TX1 from './pages/DataCenterProfiles/TX1';
import Approach from './pages/Approach';
import Cooling from './pages/Cooling';
import Team from './pages/Team';
import Partners from './pages/Partners';
import SC1Detail from './pages/SC1';
import NotFound from './pages/NotFound';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <RouteToTop />
      <ScrollToHash />
      <GlobalProvider>
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/datacenters" element={<SC1 />} />
            <Route path="/nc1" element={<NC1 />} />
            <Route path="/nd1" element={<ND1 />} />
            <Route path="/sc1" element={<SC1 />} />
            <Route path="/sd1" element={<SD1 />} />
            <Route path="/tx1" element={<TX1 />} />
            <Route path="/approach" element={<Approach />} />
            <Route path="/cooling" element={<Cooling />} />
            <Route path="/team" element={<Team />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/aboutsc1" element={<SC1Detail />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ThemeProvider>
      </GlobalProvider>
    </Router>
  );
}

export default App;
