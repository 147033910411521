const coolingConfig = {
  welcomeSection: {
    sectionTitles: ['AI DC', 'COOLING DESIGN'],
    sectionSubtitle:
      'AI data centers are the future with high density configurations, advanced GPU infrastructure, liquid cooling, high-speed networking, and unmatched scalability.',
    image: {
      src: 'https://storage.googleapis.com/tiger-dc-web/images/dc-cooling-2/l.webp',
      alt: 'Interior photograph of SC1 AI data center',
      fit: 'cover',
    },
  },
  comparisonSection: {
    sectionTitle: 'Stay relevant with purpose-built data centers',
    sectionSubtitle:
      'Explore the comparison below to understand why AI-centric \n data centers are essential for the future of data processing.',
    headerLeft: 'Feature',
    headerCenter: 'TigerDC',
    headerRight: 'Other',
    headerFarRight: '',
    headLeftBgColor: 'darkBlue',
    headCenterBgColor: 'lightBlue',
    headRightBgColor: 'darkBlue',
    headFarRightBgColor: '',
    cellTextColor: 'white',
    columnLeftBgColor: 'lightBlue',
    columnCenterBgColor: 'skyBlue',
    columnRightBgColor: 'lightBlue',
    columnFarRightBgColor: '',
    tableItems: [
      {
        columnLeft: 'Hosting Nvidia GPUs',
        columnCenter: '',
        columnRight: '',
        columnFarRight: '',
        iconCenterName: 'check',
        iconRightName: 'xmark',
        iconFarRightName: '',
      },
      {
        columnLeft: 'High-Density Racks (~100 kW)',
        columnCenter: '',
        columnRight: '',
        columnFarRight: '',
        iconCenterName: 'check',
        iconRightName: 'xmark',
        iconFarRightName: '',
      },
      {
        columnLeft: 'Direct-to-Chip Cooling',
        columnCenter: '',
        columnRight: '',
        columnFarRight: '',
        iconCenterName: 'check',
        iconRightName: 'xmark',
        iconFarRightName: '',
      },
      {
        columnLeft: 'InfiniBand Networking for Node Connection',
        columnCenter: '',
        columnRight: '',
        columnFarRight: '',
        iconCenterName: 'check',
        iconRightName: 'xmark',
        iconFarRightName: '',
      },
      {
        columnLeft: 'Easy Scalability',
        columnCenter: '',
        columnRight: '',
        columnFarRight: '',
        iconCenterName: 'check',
        iconRightName: 'xmark',
        iconFarRightName: '',
      },
      {
        columnLeft: 'Future-Proof Design',
        columnCenter: '',
        columnRight: '',
        columnFarRight: '',
        iconCenterName: 'check',
        iconRightName: 'xmark',
        iconFarRightName: '',
      },
    ],
  },
  futureProofSection: {
    sectionTitle:
      'Future-proof cooling for a future-proof data center ready for H100s and Blackwell',
    sectionSubtitle: 'Get a quick overview of our cooling solutions below.',
    headerLeft: 'Solution',
    headerCenter: 'Description',
    headerRight: 'Pro',
    headerFarRight: 'Con',
    headLeftBgColor: 'lightBlue',
    headCenterBgColor: 'lightBlue',
    headRightBgColor: 'lightBlue',
    headFarRightBgColor: 'lightBlue',
    cellTextColor: 'darkBlue',
    columnLeftBgColor: 'skyBlue',
    columnCenterBgColor: 'skyBlue',
    columnRightBgColor: 'skyBlue',
    columnFarRightBgColor: 'skyBlue',
    tableItems: [
      {
        columnLeft: 'Single-Phase Direct-to-Chip',
        columnCenter: 'Liquid transfers heat to an exchanger',
        columnRight: 'Efficient and easy implementation',
        columnFarRight: '~100 kW rack limit',
        iconCenterName: '',
        iconRightName: '',
        iconFarRightName: '',
      },
      {
        columnLeft: 'Two-Phase Direct-to-Chip',
        columnCenter: 'Phase-changing fluid transfers heat',
        columnRight: 'Highly efficient',
        columnFarRight: 'Complex, costly',
        iconCenterName: '',
        iconRightName: '',
        iconFarRightName: '',
      },
      {
        columnLeft: 'Rear Door Cooling',
        columnCenter: 'Door removes heat from the rack’s back',
        columnRight: 'Works with direct-to-chip',
        columnFarRight: 'Limited to air cooling',
        iconCenterName: '',
        iconRightName: '',
        iconFarRightName: '',
      },
      {
        columnLeft: 'Immersion Cooling',
        columnCenter: 'Servers submerged in dielectric liquid',
        columnRight: 'Highest efficiency',
        columnFarRight: 'High cost, risk of server damage',
        iconCenterName: '',
        iconRightName: '',
        iconFarRightName: '',
      },
    ],
  },
  solutionsSection: {
    sectionTitle: 'Our cooling solutions in depth',
    sectionSubtitle: 'Learn about our cooling solutions in greater detail.',
    solutions: [
      {
        imgSrc:
          'https://storage.googleapis.com/tiger-dc-web/images/dc-cooling-dtc/m.webp',
        imgLabel: 'placeholder image',
        imgFit: 'cover',
        infoTitle: 'Single-Phase Direct-to-Chip',
        infoSubtitle: 'Transfers heat to a circulating fluid',
        bgColor: 'skyBlue',
        solutionTextColor: 'darkBlue',
        featureTextColor: 'lightBlue',
        featureIconName: 'check',
        featureTexts: [
          'Uses a cold plate',
          'Fluid flows to cooling distribution unit (CDU)',
          'Ideal for AI data centers',
        ],
      },
      {
        imgSrc:
          'https://storage.googleapis.com/tiger-dc-web/images/dc-cooling-dtc-2/m.webp',
        imgLabel: 'placeholder image',
        imgFit: 'cover',
        infoTitle: 'Two-Phase Direct-to-Chip',
        infoSubtitle: 'Uses phase change for superior heat absorption',
        bgColor: 'lightBlue',
        solutionTextColor: 'white',
        featureTextColor: 'skyBlue',
        featureIconName: 'check',
        featureTexts: [
          'Fluid changes from liquid to vapor',
          'Vapor condenses back in CDU',
          'Ideal for ultra-dense AI data centers',
        ],
      },
      {
        imgSrc:
          'https://storage.googleapis.com/tiger-dc-web/images/dc-cooling-rdhx-1/m.webp',
        imgLabel: 'placeholder image',
        imgFit: 'cover',
        infoTitle: 'Rear Door Cooling',
        infoSubtitle: 'Utilizes a cooling door for heat management',
        bgColor: 'darkBlue',
        solutionTextColor: 'white',
        featureTextColor: 'skyBlue',
        featureIconName: 'check',
        featureTexts: [
          "Installed at the server rack's rear",
          'Contains heat exchangers and fans',
          'Compatible with direct-to-chip systems',
        ],
      },
    ],
  },
};
export default coolingConfig;
