import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { useSwipeable } from 'react-swipeable';
import FullscreenOverlay from './FullscreenOverlay';
import ImageDots from './ImageDots';

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: auto;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: ${({ currentIndex }) => `translateX(-${currentIndex * 100}%)`};
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  flex-shrink: 0;
  cursor: pointer;
`;

const Arrows = styled.div`
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 10;
  pointer-events: none;
  opacity: ${({ isHovered }) => (isHovered ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const ArrowButton = styled.button`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  border: none;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: 0 10px;
  margin: 10px;
  opacity: 0.7;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  pointer-events: auto;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

/**
 * The ImageSlider component renders a slider with navigation and
 * fullscreen overlay.
 *
 * @param {Object} props - Component props
 * @prop {Array} props.images - An array of objects with `url` and `title` properties
 * @prop {boolean} [props.showDots=true] - Whether to show the dot navigation
 *
 * @example
 * <ImageSlider images={[{ url: 'image1.jpg', title: 'Image 1' }, { url: 'image2.jpg', title: 'Image 2' }]} />
 */
const ImageSlider = ({ images, showDots = true }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : prevIndex
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isHovered) return;

      if (event.key === 'ArrowRight') {
        nextSlide();
      } else if (event.key === 'ArrowLeft') {
        prevSlide();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isHovered, nextSlide, prevSlide]);

  return (
    <SliderContainer
      {...handlers}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Arrows isHovered={isHovered}>
        <ArrowButton onClick={prevSlide}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </ArrowButton>
        <ArrowButton onClick={nextSlide}>
          <FontAwesomeIcon icon={faChevronRight} />
        </ArrowButton>
      </Arrows>
      <ImageWrapper currentIndex={currentIndex}>
        {images.map((image, index) => (
          <Image
            key={index}
            src={image.url}
            alt={`Slide ${index + 1}`}
            onClick={toggleFullscreen}
          />
        ))}
      </ImageWrapper>
      {showDots && (
        <ImageDots
          images={images.map((image) => image.url)}
          currentIndex={currentIndex}
          goToSlide={goToSlide}
          position="bottom"
        />
      )}
      {isFullscreen && (
        <FullscreenOverlay
          image={images[currentIndex].url}
          imageTitle={images[currentIndex].title}
          images={images.map((img) => img.url)}
          currentIndex={currentIndex}
          onClose={toggleFullscreen}
          onPrev={prevSlide}
          onNext={nextSlide}
          goToSlide={goToSlide}
        />
      )}
    </SliderContainer>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
  showDots: PropTypes.bool,
};

export default ImageSlider;
