import React from 'react';
import Styled from '@emotion/styled';
import PropTypes from 'prop-types';

import FeatureIcons from './FeatureIcons';

const FeaturesWrapper = Styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  row-gap: 2rem;

  @media (min-width: 768px) {
    height: auto;
    max-width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const FeatureSection = ({ features }) => (
  <FeaturesWrapper>
    {features.map((feature, index) => (
      <FeatureIcons
        key={index}
        iconSrc={feature.iconSrc}
        iconLabel={feature.iconLabel}
        iconBgColor={feature.iconBgColor}
        iconShrink={feature.iconShrink}
        title={feature.title}
        description={feature.description}
      />
    ))}
  </FeaturesWrapper>
);

FeatureSection.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      iconSrc: PropTypes.string.isRequired,
      iconLabel: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ),
};

export default FeatureSection;
