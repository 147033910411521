import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContext';
import Styled from '@emotion/styled';
import Icon from './Icon';
import NavMenu from './NavMenu';

const HeaderStyled = Styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkBlue};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkBlue};
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 999;
  height: 8rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6%;

  @media (min-width: 768px) {
    padding: 0 12%;
  }

  @media (min-width: 1440px) {
    padding: 0 24%;
  }
`;

const Logo = Styled.div`
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const AccentColor = Styled.span`
  color: ${({ theme }) => theme.colors.greenAccent};
`;

const HamburgerMenu = Styled.div`
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  height: 18px;
  width: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  svg {
    transition: color 0.2s ease-in-out;
  }

  svg:hover {
    color: ${({ theme }) => theme.colors.greenAccent};
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

const Header = () => {
  const { setMenuOpen, toggleMenu } = useContext(GlobalContext);

  return (
    <HeaderStyled>
      <Logo>
        <Link to="/" onClick={() => setMenuOpen(false)}>
          <AccentColor>Tiger</AccentColor>DC
        </Link>
      </Logo>
      <HamburgerMenu onClick={toggleMenu}>
        <Icon iconName="bars" size="lg" iconType="solid" />
      </HamburgerMenu>
      <NavMenu />
    </HeaderStyled>
  );
};

export default Header;
