import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const AvatarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.slate};
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: inherit;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.charcoal};
  border-radius: 50%;
`;

const ProfileAvatar = ({ src, alt, placeholder }) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <AvatarWrapper aria-label={alt}>
      {src && !imageError ? (
        <AvatarImage src={src} alt={alt} onError={handleImageError} />
      ) : (
        <Placeholder>{placeholder || alt.charAt(0)}</Placeholder>
      )}
    </AvatarWrapper>
  );
};

ProfileAvatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default ProfileAvatar;
