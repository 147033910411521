import Styled from '@emotion/styled';
import Header from '../../components/Header';
import DataCenterTemplate from '../../components/DataCenterPages/DataCenterTemplate';
import SiteMap from '../../components/SiteMap';
import Footer from '../../components/Footer';
import dataCentersConfig from '../../components/DataCenterPages/config';

const PageWrapper = Styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const ND1 = () => {
  const { pageTitle, pageSubtitle, subtitleWidth } =
    dataCentersConfig.nd1.headlineSection;
  const { sliderImages } = dataCentersConfig.nd1.imageSlider;
  const { specs } = dataCentersConfig.nd1;
  return (
    <PageWrapper>
      <Header />
      <DataCenterTemplate
        pageTitle={pageTitle}
        pageSubtitle={pageSubtitle}
        subtitleWidth={subtitleWidth}
        sliderImages={sliderImages}
        specs={specs}
      />
      <SiteMap />
      <Footer />
    </PageWrapper>
  );
};
export default ND1;
