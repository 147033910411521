import styled from '@emotion/styled';
import SecondaryTitle from '../../styles/SecondaryTitle';
import Subtitle from '../../styles/Subtitle';
import PropTypes from 'prop-types';

const PagePadding = styled.div`
  box-sizing: border-box;
  padding: 0 6%;

  @media (min-width: 768px) {
    padding: 0 12%;
  }

  @media (min-width: 1440px) {
    padding: 0 24%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 2rem 0;

  @media (min-width: 768px) {
    gap: 4rem;
    padding: 4rem 0;
  }
`;

const TitleArea = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Title = styled(SecondaryTitle)`
  color: ${({ theme }) => theme.colors.darkBlue};
`;

const Caption = styled(Subtitle)``;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  grid-gap: 2rem;
  grid-column-gap: 3rem;

  @media (min-width: 768px) {
    grid-gap: 4rem;
    grid-column-gap: 6rem;
  }
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;

const ItemTitle = styled(SecondaryTitle)`
  color: ${({ theme }) => theme.colors.charcoal};
  @media {
    font-size: 2rem;
  }
`;

const ItemDescription = styled(Subtitle)`
  text-align: left;

  @media (min-width: 768px) {
    text-align: justify;
  }
`;

const InfoGrid = ({ title, caption, gridItems }) => {
  return (
    <PagePadding>
      <Wrapper>
        <TitleArea>
          <Title as="h3">{title}</Title>
          {caption && <Caption>{caption}</Caption>}
        </TitleArea>
        <Grid>
          {gridItems.map((gridItem, index) => (
            <GridItem key={index}>
              <ItemTitle as="h4">{gridItem.itemTitle}</ItemTitle>
              <ItemDescription>{gridItem.itemDescription}</ItemDescription>
            </GridItem>
          ))}
        </Grid>
      </Wrapper>
    </PagePadding>
  );
};

InfoGrid.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      itemTitle: PropTypes.string.isRequired,
      itemDescription: PropTypes.string.isRequired,
    })
  ),
};

export default InfoGrid;
