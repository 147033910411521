import Styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Icon from './Icon';
import Subtitle from '../styles/Subtitle';
import SecondaryTitle from '../styles/SecondaryTitle';

const Feature = Styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4rem;

  @media (min-width: 768px) {
    max-width: 30rem;
    flex-direction: column;
    flex: 1;
    gap: 2rem;
  }
`;

const IconWrapper = Styled.div`
  border-radius: 100%;
  background-color: ${({ theme, iconBgColor }) => theme.colors[iconBgColor]};
  box-sizing: border-box;
  height: 8rem;
  width: 8rem;
  padding: calc(${({ iconShrink }) => `${iconShrink}`}rem - 1rem);

  //filter applied to icons to match darkBlue color
  img {
    filter: invert(16%) sepia(64%) saturate(607%) hue-rotate(173deg) brightness(95%) contrast(93%);
  }

  @media (min-width: 768px) {
    height: 14rem;
    width: 14rem;
    padding: ${({ iconShrink }) => `${iconShrink}`}rem;
    }
  `;

const TextWrapper = Styled.div`
  text-align: left;
  width: 15rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.4rem;

  @media (min-width: 768px) {
    width: 80%;
    text-align: center;
    align-items: center;
    flex: 1;
    gap: 1rem;

    p {
      font-size: ${({ theme }) => theme.fontSizes.regular};
      line-height: calc(${({ theme }) => theme.lineHeight.bodySmall} + 0.5rem);
    }
  }

  @media (min-width: 1440px) {
    p {
      font-size: ${({ theme }) => theme.fontSizes.medium};
      line-height: ${({ theme }) => theme.lineHeight.bodyRegular}
    }
  }
`;

const IconTitle = Styled(SecondaryTitle)``;

const IconSubtitle = Styled(Subtitle)``;

const FeatureIcons = ({
  iconSrc,
  iconLabel,
  title,
  description,
  iconBgColor,
  iconShrink,
}) => {
  return (
    <Feature>
      <IconWrapper iconBgColor={iconBgColor} iconShrink={iconShrink}>
        <Icon iconName={iconLabel} iconType={'image'} url={iconSrc} />
      </IconWrapper>
      {(title || description) && (
        <TextWrapper>
          {title && <IconTitle>{title}</IconTitle>}
          {description && <IconSubtitle>{description}</IconSubtitle>}
        </TextWrapper>
      )}
    </Feature>
  );
};

FeatureIcons.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  iconLabel: PropTypes.string.isRequired,
  iconBgColor: PropTypes.string,
  iconShrink: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default FeatureIcons;
