import { Link } from 'react-router-dom';
import Styled from '@emotion/styled';
import Banner from '../Banner';
import FeatureSection from '../FeatureSection';
import Subtitle from '../../styles/Subtitle';
import homePageConfig from './config';

const SectionWrapper = Styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 6%;

  @media (min-width: 768px) {
    padding: 0 12%:
  }

  @media (min-width: 1440px) {
    padding: 0 24%;
  }
`;

const FeatureSectionWrapper = Styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;

  @media (min-width: 768px) {
    margin-top: 10rem;
  }
`;

const MoreAbout = Styled(Subtitle)`
  color: ${({ theme }) => theme.colors.darkBlue};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
`;

const MoreLink = Styled(Link)`
  text-align: right;
  margin-left: auto;
`;

const CoolingSection = () => {
  const { banner, features, moreLink } = homePageConfig.coolingSection;
  return (
    <SectionWrapper>
      <div id="learnmore" />
      <Banner
        src={banner.src}
        alt={banner.alt}
        title={banner.title}
        overlayColor={banner.overlayColor}
        overlayOpacity={banner.overlayOpacity}
      />
      <FeatureSectionWrapper>
        <FeatureSection features={features} />
      </FeatureSectionWrapper>
      <MoreAbout>
        <MoreLink to={moreLink.path}>{moreLink.text}</MoreLink>
      </MoreAbout>
    </SectionWrapper>
  );
};

export default CoolingSection;
