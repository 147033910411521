import PropTypes from 'prop-types';
import Styled from '@emotion/styled';
import PrimaryTitle from '../styles/PrimaryTitle';
import SecondaryTitle from '../styles/SecondaryTitle';
import Subtitle from '../styles/Subtitle';

const HeadlineSection = ({
  titleType,
  titleWidth,
  subtitleWidth = 85,
  paddingPreset = 85,
  bgColor,
  titleColor,
  subtitleColor,
  sectionTitle,
  sectionSubtitle,
}) => {
  const paddingPresets = {
    topOfPage: `
      padding: 0 6%;
      padding-top: 10rem;
      padding-bottom: 2rem;

      @media (min-width: 768px) {
        padding: 0 12%;
        padding-top: 12rem;
        padding-bottom: 4rem;
      }

      @media (min-width: 1440px) {
        padding: 0 24%;
        padding-top: 12rem;
        padding-bottom: 4rem;
      }
    `,
    insidePage: `
      padding: 2rem 0;

      @media (min-width: 768px) {
        padding: 4rem 0;
      }

      @media (min-width: 1440px) {
        padding: 4rem 0;
      }
    `,
  };

  const HeadlineArea = Styled.div`
  ${(props) => paddingPresets[props.paddingPreset] || paddingPresets.topOfPage};

    background-color: ${({ theme }) => theme.colors[bgColor]};
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
  const TitleComponent =
    titleType === 'primary' ? PrimaryTitle : SecondaryTitle;

  const SectionTitle = Styled(TitleComponent)`
    color: ${({ theme }) => theme.colors[titleColor]};
    width: ${({ titleWidth }) => `${titleWidth}%`};
`;

  const SectionSubtitle = Styled(Subtitle)`
    color: ${({ theme }) => theme.colors[subtitleColor]};
    width: ${({ subtitleWidth }) => `${subtitleWidth}%`};
    padding-top: 0.5rem;

    @media (min-width: 768px) {;
      padding-top: 1.5rem;
    }
`;

  return (
    <HeadlineArea paddingPreset={paddingPreset}>
      <SectionTitle titleWidth={titleWidth}>{sectionTitle}</SectionTitle>
      {sectionSubtitle && (
        <SectionSubtitle subtitleWidth={subtitleWidth}>
          {sectionSubtitle}
        </SectionSubtitle>
      )}
    </HeadlineArea>
  );
};

HeadlineSection.propTypes = {
  titleType: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  paddingPreset: PropTypes.oneOf(['topOfPage', 'insidePage']).isRequired,
  titleWidth: PropTypes.number.isRequired,
  subtitleWidth: PropTypes.number,
  bgColor: PropTypes.string,
  titleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
  sectionTitle: PropTypes.string.isRequired,
  sectionSubtitle: PropTypes.string,
};

export default HeadlineSection;
