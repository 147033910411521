import Styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import PrimaryTitle from '../../styles/PrimaryTitle';
import Subtitle from '../../styles/Subtitle';
import homePageConfig from './config';

const SectionWrapper = Styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  max-height: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 6%;
  
  @media (min-width:768px) {
    align-items: center;
    padding: 0 12%;
    padding-bottom: 6rem;
  }

  @media (min-width: 1440px) {
    padding: 0 24%;
    padding-bottom: 6rem;
  }
`;

const SectionContainer = Styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* Use clamp() to set padding-top between 100px and 200px */
  padding-top: clamp(100px, calc((100vh - 600px) / 2), 200px);
`;

const TitleWrapper = Styled(PrimaryTitle)`
  font-style: normal;
  font-size: 4.5rem;
  padding-top: 7rem;
  line-height: 4rem;

  @media (min-width: 768px) {
    padding-top: 0;
    line-height: 7vw;

  @media (min-width: 1440px) {
    padding-top: 0;
    line-height: 5.5vw;
`;

const Title = Styled.div`
  color: ${({ color, theme }) => theme.colors[color] || theme.colors.text};
`;

const SectionSubtitle = Styled(Subtitle)`
  line-height: 1.5rem;
  padding-top: 4rem;
  max-width: 80%;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    line-height: 2rem;
    max-width: 70%;
  }
  @media (min-width: 1024px) {
    font-size: 2rem;
    line-height: 2.5rem;
    max-width: 70%;
  }
`;

const LearnMore = Styled(Subtitle)`
  text-align: left;
  padding-top: 7rem;
`;

const HeroSection = () => {
  const { sectionTitles, sectionSubtitle } = homePageConfig.heroSection;

  return (
    <SectionWrapper>
      <SectionContainer>
        <TitleWrapper>
          {sectionTitles.map((sectionTitle, index) => (
            <Title key={index} color={index === 1 ? 'lightBlue' : 'darkBlue'}>
              {sectionTitle}
            </Title>
          ))}
        </TitleWrapper>
        <SectionSubtitle>{sectionSubtitle}</SectionSubtitle>
        <LearnMore>
          <Link to="#learnmore">Learn More ↓</Link>
        </LearnMore>
      </SectionContainer>
    </SectionWrapper>
  );
};

export default HeroSection;
