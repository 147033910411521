import Header from '../components/Header';
import Footer from '../components/Footer';
import SiteMap from '../components/SiteMap';

import PageCover from '../components/AboutDataCenterPages/PageCover';
import SectionHeader from '../components/AboutDataCenterPages/SectionHeader';
import Banner from '../components/Banner';
import InfoGrid from '../components/AboutDataCenterPages/InfoGrid';
import IconStrip from '../components/AboutDataCenterPages/IconStrip';
import FeatureTable from '../components/AboutDataCenterPages/FeatureTable';
import LocationsMap from '../components/AboutDataCenterPages/LocationsMap';
import aboutSc1Config from '../components/AboutDataCenterPages/config';

const SC1 = () => {
  const { topHeadline, topCaption } = aboutSc1Config.contentTop;
  const { bannerSrc, bannerAltLabel } = aboutSc1Config.banner;
  const { gridTitle, gridItems } = aboutSc1Config.infoGrid;
  const {
    iconStripBgColor,
    iconStripTitleColor,
    iconTitleColor,
    iconDescriptionColor,
    iconStripTitle,
    iconStripIcons,
  } = aboutSc1Config.iconStrip;
  const {
    tableHeadline,
    tableCaption,
    tableImageSrc,
    tableImageLabel,
    tableTitle,
    tableLists,
  } = aboutSc1Config.featureTable;
  const { mapTitle, mapCaption, mapLocations } = aboutSc1Config.locationsMap;
  const { grid2Title, grid2Caption, grid2Items } = aboutSc1Config.infoGrid2;
  return (
    <>
      <Header />
      <PageCover />
      <SectionHeader headline={topHeadline} caption={topCaption} />
      <Banner src={bannerSrc} alt={bannerAltLabel} />
      <InfoGrid title={gridTitle} gridItems={gridItems} />
      <IconStrip
        bgColor={iconStripBgColor}
        titleColor={iconStripTitleColor}
        iconTitleColor={iconTitleColor}
        iconDescriptionColor={iconDescriptionColor}
        iconStripTitle={iconStripTitle}
        features={iconStripIcons}
      />
      <SectionHeader headline={tableHeadline} caption={tableCaption} />
      <FeatureTable
        tableImageSrc={tableImageSrc}
        tableImageLabel={tableImageLabel}
        tableTitle={tableTitle}
        tableLists={tableLists}
      />
      <SectionHeader headline={mapTitle} caption={mapCaption} />
      <LocationsMap mapLocations={mapLocations} />
      <InfoGrid
        title={grid2Title}
        caption={grid2Caption}
        gridItems={grid2Items}
      />
      <SiteMap />
      <Footer />
    </>
  );
};

export default SC1;
