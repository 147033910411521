import Styled from '@emotion/styled';
import HeadlineSection from '../HeadlineSection';
import SolutionsFeatureSection from './SolutionsFeatureSection';
import coolingConfig from './config';

const SectionWrapper = Styled.div`
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 6%;
  padding-bottom: 2rem;

  @media (min-width: 768px) {
    padding: 0 12%;
    padding-bottom: 6rem;
  }

  @media (min-width: 1440px) {
    padding: 0 24%;
    padding-bottom: 6rem;
  }
`;

const SolutionsSection = () => {
  const { sectionTitle, sectionSubtitle, solutions } =
    coolingConfig.solutionsSection;

  return (
    <SectionWrapper>
      <HeadlineSection
        titleType="secondary"
        titleWidth={100}
        subtitleWidth={100}
        paddingPreset="insidePage"
        titleColor="darkBlue"
        subtitleColor="text"
        sectionTitle={sectionTitle}
        sectionSubtitle={sectionSubtitle}
      />
      <SolutionsFeatureSection solutions={solutions} />
    </SectionWrapper>
  );
};
export default SolutionsSection;
