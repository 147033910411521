import styled from '@emotion/styled';
import SecondaryTitle from '../../styles/SecondaryTitle';
import Subtitle from '../../styles/Subtitle';
import PropTypes from 'prop-types';

const PagePadding = styled.div`
  box-sizing: border-box;
  padding: 0 6%;

  @media (min-width: 768px) {
    padding: 0 12%;
  }

  @media (min-width: 1440px) {
    padding: 0 24%;
  }
`;

const HeaderArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 2rem 0;

  @media (min-width: 768px) {
    gap: 2rem;
    padding: 4rem 0;
  }
`;
const Headline = styled(SecondaryTitle)`
  color: ${({ theme }) => theme.colors.darkBlue};
  text-align: center;
  width: 90%;

  @media (min-width: 768px) {
    width: 65%;
  }
`;
const Caption = styled(Subtitle)`
  text-align: justify;
  text-align-last: center;

  @media (min-width: 768px) {
    width: 85%;
  }
`;
const SectionHeader = ({ headline, caption }) => {
  return (
    <PagePadding>
      <HeaderArea>
        <Headline as="h3">{headline}</Headline>
        <Caption>{caption}</Caption>
      </HeaderArea>
    </PagePadding>
  );
};

SectionHeader.propTypes = {
  headline: PropTypes.string.isRequired,
  caption: PropTypes.string,
};

export default SectionHeader;
